import React from 'react';
import { GlobalProvider } from './src/context/GlobalContext';
import { node } from 'prop-types';
import 'focus-visible';

wrapRootElement.propTypes = {
	element: node
};

export function wrapRootElement({ element }) {
	return <GlobalProvider>{element}</GlobalProvider>;
}
