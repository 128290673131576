import React, { createContext } from 'react';
import { node } from 'prop-types';
import { noop } from '../util/functions';
import useBatchState from '../hooks/useBatchState';

const GlobalContext = createContext({
	email: '',
	setGlobal: noop
});

const { Provider } = GlobalContext;

GlobalProvider.propTypes = {
	children: node
};

export function GlobalProvider({ children }) {
	const [state, setState] = useBatchState({
		email: ''
	});

	const value = {
		...state,
		setGlobal: setState
	};

	return <Provider value={value}>{children}</Provider>;
}

export default GlobalContext;
